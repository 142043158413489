<template>
  <div class="page-not-found">
    <h3>
        Page not found.
    </h3>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.page-not-found{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    h3{
        font-weight: 700;
        font-family: sans-serif;
        font-size: 48px;
    }
}
</style>