<template>
  <div id="app">
    <div id="nav">
      <img @click="goTo('')" src="@/assets/icons/MPCARShorizontal.png" alt="logo">
      <div class="links">
        <a @click="goTo('#home')" href="#home">Domov</a>
        <a @click="goTo('#about')" href="#about">O nás</a>
        <a @click="goTo('#carpark')" href="#carpark">Vozový park</a>
        <a @click="goTo('#contact')" href="#contact">Kontakt</a>
      </div>
    </div>
    <router-view/>
    <FooterView id="contact"/>
  </div>
</template>

<script lang="js">
import FooterView from '@/views/FooterView.vue'

export default {
  name: 'App',
  components:{
    FooterView
  },
  methods: {
    goTo(id) {
      this.$router.push('/' + id)
    }
  }
}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  font-family: 'UniviaPro-Regular';
}
@font-face {
  font-family: UniviaPro-Bold;
  src: url('./assets/fonts/UniviaPro-Bold.woff')  format('truetype');
}
@font-face {
  font-family: UniviaPro-Regular;
  src: url('./assets/fonts/UniviaProRegular.woff')  format('truetype');
}
#app{
  margin-top: 40px;
  #nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
    img{
      width: 30%;
      cursor: pointer;
    }
    .links{
      display: flex;
      align-items: center;
      a{
        text-decoration: none;
        margin-right: 30px;
        font-size: 20px;
        font-weight: 700;
        font-family: 'UniviaPro-Bold';
        color: #001448;
        cursor: pointer;
      }
      a:hover{
        border-bottom: 2px solid #0077B6;
      }
      .active-link{
        border-bottom: 2px solid #0077B6;
      }
      a:last-child{
        margin-right: 0;
        border-radius: 10px;
        padding: 5px 10px;
        background-color: #001448;
        border: 1px solid #001448;
        color: white;
      }
      a:last-child:hover{
        border-radius: 10px;
        background-color: white;
        color: #001448;
        border: 1px solid #001448;
      }
    }
  }
}
@media screen and (max-width: 750px) {
#app{
  margin-top: 10px !important;
}
#nav{
  display: block !important;
  text-align: center;
  img{
    width: 50% !important;
    margin-bottom: 20px;
  }
  .links{
    justify-content: center;
  }
}
}
@media screen and (max-width: 600px) {
#nav{
  img{
    width: 70% !important;
  }
  .links{
    justify-content: center;
  }
}
}
@media screen and (max-width: 500px) {
#nav{
  img{
    width: 80% !important;
  }
  .links{
    a{
      font-size: 16px !important;
      margin-right: 10px !important;
    }
  }
}
}
</style>
